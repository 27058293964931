.info-icon-wrapper {
  border-radius: 12px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: var(--hover-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}
