.panel {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--main-background-color);
  border-left: 1px solid var(--button-background-color);
  opacity: 1;
  transition: 0.3s ease all;
  z-index: 10;
}

.panel-hidden {
  opacity: 0;
  pointer-events: none;
}
