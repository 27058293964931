.drawer-paper {
  box-shadow: none;
}

.backdrop {
  background: none;
}

.wrapper {
  background: var(--main-background-color);
  padding: 27px 0 0 0;
  width: 728px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 0 32px;
}

.button-back {
  padding: 0;
  margin-right: 16px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
}

.arrow-icon path {
  fill: var(--link-color);
}

.close-button {
  margin-left: auto;
  padding: 0;
}

.choose-provider {
  padding: 0 32px;
}

.subtitle {
  margin-bottom: 24px;
}

.provider {
  margin-bottom: 16px;
  display: flex;
  background: var(--main-background-color);
  align-items: center;
  height: 64px;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
}

.provider-icon-wrapper {
  border-radius: 8px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: var(--hover-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  border: 1px solid var(--button-background-color);
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-icon path,
.info-icon circle {
  stroke: #ff6f30;
}

.input-tooltip {
  background: var(--main-background-color);
  color: #2c2a29;
  border: 1px solid #d3d3d3;
}

.warning-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
