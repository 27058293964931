.drawer-paper {
  top: 213px;
  width: 100%;
  max-width: 400px;
  border: none;
  box-shadow: none;
}

.wrapper {
  height: calc(100vh - 226px);
  width: 100%;
  max-width: 400px;
  border-left: 1px solid var(--button-background-color);
}

.header {
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--button-background-color);
}

.close-button:hover,
.button:hover {
  background: var(--selected-background-color) !important;
  border-radius: 0;
}

.filter-form {
  padding: 24px;
  height: calc(100vh - 383px);
  overflow-y: auto;
  overflow-x: hidden;
}

.input-title {
  margin: 32px 0 8px;
}

.bottom-buttons {
  padding: 24px 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 88px;
  border-top: 1px solid var(--button-background-color);
}

.count {
  margin: 0 0 0 8px;
  min-width: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--main-background-color);
  font-size: 13px;
}

.calendar-bar {
  padding: 0 24px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-picker {
  margin-bottom: 8px;
  padding-left: 16px;
  width: 100%;
  height: 40px;
  border: 1px solid var(--button-background-color);
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3c4567;
}

.date-picker__error:focus {
  border-width: 2px !important;
}

.error-message {
  color: #d32f2f;
  font-size: 14px;
  line-height: 21px;
}
