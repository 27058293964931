.wrapper {
  height: calc(100vh - 281px);
  overflow: auto;
  padding-bottom: 32px;
}

.admin-wrapper {
  height: calc(100vh - 340px);
}

.info {
  max-width: 800px;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
  padding: 20px 24px;
  margin-top: 32px;
}

.info + .info {
  margin-top: 32px;
}

.info-icon-wrapper {
  align-self: flex-start;
  border-radius: 12px;
  min-width: 40px;
  min-height: 40px;
  background: var(--hover-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container + .flex-container {
  margin-top: 24px;
}

.align-center {
  align-items: center;
}

.info-title {
  margin-bottom: 28px;
}

.info-item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-description-wrapper .show-full:hover {
  background: none;
}

.client-description {
  word-break: break-word;
}

.info-icon circle,
.info-icon path {
  stroke: var(--main-color);
}

.eye-icon path {
  fill: var(--main-color);
}

@media screen and (max-width: 1023px) {
  .wrapper {
    height: calc(100vh - 314px);
  }

  .admin-wrapper {
    height: calc(100vh - 372px);
  }
}
