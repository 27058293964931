.footer-wrapper {
  width: 100%;
  display: grid;
  place-content: center;
  background-color: var(--main-background-color);
  border-top: 1px solid var(--button-background-color);
}

.footer {
  display: flex;
  gap: 16px;
  padding: 8px 32px;
}

.link {
  color: var(--link-color); /* Наследуем цвет текста */
  text-decoration: none; /* Убираем подчеркивание */
  cursor: pointer; /* Указываем, что элемент кликабельный */
}
