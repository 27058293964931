.wrapper {
  padding: 32px 32px 20px 32px;
  width: 100%;
  height: fit-content;
}

.button-back {
  padding: 0;
}

.button-back:hover {
  background: none;
}

.input {
  padding-left: 48px !important;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.profile-provider {
  width: 100%;
  max-width: 600px;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.provider {
  display: flex;
  align-items: center;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  padding: 12px 20px 12px 24px;
  margin: 16px 0;
  cursor: pointer;
}

.provider:hover {
  background: var(--background-darkened-color);
}

.provider-icon-wrapper {
  border-radius: 8px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: var(--main-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  border: 1px solid var(--button-background-color);
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 1023px) {
  .wrapper {
    padding: 24px 16px 20px 16px;
  }
}
