.button-back {
  margin-bottom: 24px;
  padding: 0;
}

.button-back:hover {
  background: none;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.panel {
  padding: 32px;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
}

.title {
  padding-bottom: 32px;
}

.info {
  display: flex;
  flex-direction: column;
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: nowrap;
  height: 44px;
}

.info-item:not(:last-child) {
  margin-bottom: 24px;
}

.info-item-title {
  width: 100%;
  max-width: 300px;
}

.value-wrap {
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 636px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid var(--button-background-color);
}

.info-item-value {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
