.bar {
  padding: 0 32px 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid var(--button-background-color);
}

.search {
  margin-right: 16px;
  min-width: 120px;
  width: 400px;
  height: 40px;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.input {
  padding-left: 48px !important;
}

.action-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
}

.refresh-button {
  margin: 0 16px;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: loading 2s linear infinite;
}

.download-button {
  margin-right: 16px;
}

.filter-button {
  position: relative;
  margin-right: 20px;
  padding: 0;
  width: 40px;
  height: 40px;
}

.filter-count {
  position: absolute;
  background: #ff615e;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 5%;
  right: 5%;
  font-size: 10px;
  color: var(--main-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-popover-paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  border-radius: 1px;
  width: 372px;
  height: 186px;
}

.view {
  padding-right: 0;
  height: 56px;
}

.view:hover {
  background: none;
}

.view-icon {
  margin-left: 4px;
  margin-right: 0;
}

.content {
  position: relative;
  width: 100%;
  height: calc(100vh - 226px);
}

.log-table {
  width: calc(100% - 549px);
  height: calc(100vh - 226px);
  transition: 0.5s ease all;
}

.full-width {
  transition: 0.5s ease all;
  width: 100%;
}

.log-table-wrapper {
  height: 100%;
}

.animate-width,
.animate-width div {
  transition: 0.5s ease all;
}

.table-row {
  padding-left: 24px;
  border-bottom: 1px solid var(--button-background-color);
  cursor: pointer;
}

.content-row {
  padding-right: 0 !important;
  width: 100% !important;
}

.content-row:hover {
  background: var(--background-darkened-color);
}

.selected-row {
  background: var(--selected-background-color);
}

.selected-row:hover {
  background: var(--hover-background-color);
}

.table-cell {
  padding: 0;
  flex: 1;
  height: 56px;
  border: none;
}

.table-cell .open-actions-icon:hover,
.active-actions-icon {
  background: var(--button-background-color);
}

.table-cell .open-actions-icon:hover path,
.active-actions-icon path {
  fill: rgba(0, 0, 0, 0.8);
}

.flex-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.header-cell {
  padding: 0;
  height: 56px;
  white-space: nowrap;
}

.header-button {
  padding: 0;
  min-width: 0;
  height: 100%;
}

.header-button:hover {
  background: none !important;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rotate-180 {
  transform: rotate(180deg);
}

.event-avatar {
  margin-right: 16px;
  min-width: 24px;
}

.empty {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty-inner {
  position: relative;
}

.empty-icon {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);
  width: 140px;
  height: 140px;
}

@media screen and (max-width: 1023px) {
  .bar {
    padding: 0;
  }

  .table-row {
    padding-left: 0;
  }
}

@media screen and (max-width: 576px) {
  .search {
    margin-right: 8px;
  }

  .input {
    padding-left: 28px !important;
  }

  .download-button {
    margin-right: 8px;
  }

  .select {
    margin-right: 8px;
    width: 100px;
  }

  .check-icon {
    left: 5px;
    top: 35%;
  }

  .select-item {
    padding-left: 35px;
  }
}
