@font-face {
  font-family: 'Golos';
  font-style: normal;
  font-weight: 500;
  src: local('Golos'), local('Golos-Regular'),
    url('./fonts/Golos-Text_Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Golos';
  font-style: normal;
  font-weight: 400;
  src: local('Golos'), local('Golos-Regular'),
    url('./fonts/Golos-Text_Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'BasicallyASansSerif';
  font-style: normal;
  font-weight: 600;
  src: local('BasicallyASansSerif'), local('BasicallyASansSerif-SemiBold'),
    url('./fonts/BasicallyASansSerif-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter'), local('Inter-Regular'), url('./fonts/Inter-400.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter'), local('Inter-Regular'), url('./fonts/Inter-500.ttf') format('truetype');
}

.css-19kzrtu {
  padding: 0;
}
*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  color: var(--main-background-color);
}
*::-webkit-scrollbar-thumb {
  background-color: #ced0d9;
  border-radius: 8px;
}
.App {
  text-align: center;
}

:root {
  --main-background-color: rgb(30, 28, 25);
  --selected-background-color: #24221e;
  --hover-background-color: #201f1b;
  --background-disabled-color: #2a2723;
  --button-background-color: #2d2b26;
  --background-darkened-color: rgb(16, 48, 48);
  --link-color: #201f1b;
  --main-color: #201f1b;
  --content-position: 'start';
}

#root {
  background-color: var(--main-background-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.animate-color,
.animate-color * {
  transition: 0.3s ease background-color;
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 599.95px) {
  .notistack-SnackbarContainer {
    width: auto !important;
  }
}

@media screen and (max-width: 432px) {
  .notistack-SnackbarContainer {
    width: 100% !important;
  }
}

.wrapper-scroll,
.wrapper {
  width: 100%;
  height: calc(100vh - 118px);
  display: flex;
  place-content: var(--content-position);
}

.wrapper-scroll {
  overflow-y: auto;
}

.content {
  padding: 32px;
  width: 100%;
  max-width: 1032px;
}
