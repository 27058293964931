.wrapper {
  padding: 0 32px 32px 32px;
  height: 100%;
  overflow: hidden auto;
}

.info {
  width: 469px;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
  padding: 20px 24px;
  margin-top: 32px;
}

.info + .info {
  margin-top: 32px;
}

.info-icon-wrapper {
  align-self: flex-start;
  border-radius: 12px;
  min-width: 40px;
  min-height: 40px;
  background: var(--selected-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-container + .flex-container {
  margin-top: 24px;
}

.align-center {
  align-items: center;
}

.info-title {
  margin-bottom: 28px;
}

.info-item {
  max-width: calc(100% - 60px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-height-80 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.client-description {
  word-break: break-all;
}

.client-description-wrapper {
  width: 100%;
}

.client-description-wrapper .show-full:hover {
  background: none;
}

.show-full {
  padding: 0;
  margin-top: 9px;
}

.info-icon circle,
.info-icon path {
  stroke: var(--main-color);
}

.eye-icon path {
  fill: var(--main-color);
}
