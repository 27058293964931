.applications {
  padding: 0 0 0 32px;
  flex-grow: 1;
  height: calc(100vh - 118px);
  overflow: hidden;
}

.title-app {
  font-size: 24px;
  font-weight: 500;
}

.applications-top {
  padding: 0 32px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 94px;
}

.admin-buttons::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.arrow-right {
  transform: rotate(-90deg);
}

.start-icon-button path {
  stroke: var(--main-background-color);
  stroke-width: 2;
}

.add-button {
  margin-right: 16px;
}

.end-icon-button {
  margin-left: auto;
}

.admin-button + .admin-button {
  margin-left: 16px;
}

.tabs-wrapper {
  padding-right: 32px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid var(--button-background-color);
  box-sizing: border-box;
  height: 56px;
  align-items: center;
}

.tabs .view {
  margin-left: auto;
  padding-right: 0;
}

.tabs .view:hover {
  background: none;
}

.view-icon {
  margin-left: 4px;
  margin-right: 0;
}

.view-popover-paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  border-radius: 1px;
  width: 372px;
  height: 186px;
}

.tab-container {
  display: flex;
  width: 100%;
  position: relative;
}

.button-back {
  padding: 0;
}

.applications .button-back:hover {
  background: none;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inner {
  position: relative;
}

.search-icon {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);
  width: 80px;
  height: 80px;
}

@media screen and (max-height: 500px) {
  .applications {
    overflow: scroll;
  }
}
