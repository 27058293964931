.panel {
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-left: 1px solid var(--button-background-color);
  transition: 0.3s ease all;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--main-background-color);
  opacity: 1;
  transition: 0.3s ease all;
}

.tabs .tab-indicator {
  height: 1px;
}

.tabs {
  padding: 0 12px 0 32px;
  border-bottom: 1px solid var(--button-background-color);
}

.tabs-wrapper {
  border-bottom: 1px solid var(--button-background-color);
}

.tabs .tab {
  padding: 0;
  min-width: 0;
  width: fit-content;
  height: 56px;
  text-transform: none;
}

.tab + .tab {
  margin-left: 32px;
}

.tabs .view {
  margin-left: auto;
  padding-right: 0;
}

.tabs .view:hover {
  background: none;
}

.panel-hidden {
  opacity: 0;
  pointer-events: none;
}

.panel-top {
  display: flex;
  padding: 32px;
}

.app-icon-wrapper {
  flex-shrink: 0;
  border: 1px solid var(--button-background-color);
  border-radius: 8px;
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.app-icon {
  width: 40px;
  height: 40px;
}

.name-wrapper {
  margin-left: 32px;
  max-width: calc(100% - 164px);
}

.edit-link {
  display: block;
  margin-left: 24px;
}

.edit-button {
  padding: 8px;
}

.edit-button:focus {
  padding: 6px;
}

.edit-button:active {
  padding: 8px;
}

.go-over-wrapper {
  padding: 8px;
  border-radius: 1px;
}

.go-over-button:hover {
  background: var(--selected-background-color) !important;
}

.action-button-icon {
  margin-right: 16px;
}

.paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  transition: none !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: var(--main-background-color);
}

.divider {
  height: 1px;
  width: 100%;
  background: var(--button-background-color);
  margin-top: 8px;
}

.action-button:last-child {
  margin-top: 8px;
}

.info-icon path,
.info-icon circle {
  stroke: var(--main-color);
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-tooltip {
  background: var(--main-color);
}

.input-tooltip-arrow {
  color: var(--main-color);
}

.delete-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-buttons {
  margin-top: 16px;
  display: flex;
  align-items: flex-end;
}

.panel-empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
